import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import StaticModal from '../../elements/Modal2';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  const [privacyState, setPrivaryState] = useState(false)
  const [returnState, setReturnState] = useState(false)


  return (
    <>
      {privacyState &&
        <StaticModal title="Privacy Policy" body={<>
          <b>Introduction:</b><br />
          Welcome to Lotus Printing Technology, where we prioritize the privacy and security of our customers. This Privacy Policy outlines how we collect, use, and protect your information when you interact with our services.
          <br /> <br />
          <b>Information We Collect:</b><br />
          We may collect personal information such as names, addresses, phone numbers, and email addresses when you place an order or engage with our website. We also collect non-personal information through cookies and similar technologies to enhance your browsing experience.
          <br /> <br />
          <b>How We Use Your Information:</b><br />
          The information we collect is used for order processing, customer support, and to improve our services. We do not sell or share your personal information with third parties without your explicit consent.
          <br /> <br />
          <b>Data Security:</b><br />
          We take appropriate measures to protect your information from unauthorized access, disclosure, alteration, and destruction. Our website uses secure encryption protocols to ensure the security of your personal data.
          <br /> <br />
          <b>Cookies and Tracking:</b><br />
          We use cookies to enhance your online experience. These cookies help us analyze website traffic and tailor our services to your preferences. You can manage your cookie preferences through your browser settings.
          <br /> <br />
          <b>User Rights:</b><br />
          You have the right to access, correct, or delete your personal information. If you have any questions or concerns about your privacy, please contact us at [lotusprintingtechnology@email.com].
          <br /><br />
          <b>Policy Updates:</b><br />
          This Privacy Policy is subject to periodic updates. Any changes will be reflected on this page, and the date of the last revision will be provided.
        </>} handleClose={() => setPrivaryState(false)} />}
        {returnState &&
        <StaticModal title="Return Policy" body={<>
          <b>Introduction:</b><br />
          At Lotus Printing Technology, we strive to provide high-quality products. If, however, you are not satisfied with your purchase, this Return Policy outlines the procedures for returns and exchanges.
          <br /> <br />
          <b>Eligibility:</b><br />
          To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
          <br /> <br />
          <b>Return Process:</b><br />
          To initiate a return, please contact our customer support team at lotusprintingtechnology@gmail.com. Provide your order number and details about the item you wish to return. Our team will guide you through the process.
          <br /> <br />
          <b>Refunds or Exchanges:</b><br />
          Upon receiving your returned item, we will inspect it and notify you of the status. If eligible, you may choose a refund or exchange for an alternative product. Refunds will be issued to the original payment method.
          <br /> <br />
          <b>Timelines:</b><br />
          We aim to process returns within 30 days. Refunds may take additional time depending on your payment provider. Exchanges will be shipped promptly upon confirmation.
          <br /> <br />
          <b>Return Shipping:</b><br />
          Customers are responsible for return shipping costs, unless the return is due to a defect or an error on our part. In such cases, we will cover the return shipping costs.
          <br /><br />
          <b>Contact Information:</b><br />
          For any questions or concerns related to returns, please contact our customer support team at lotusprintingtechnology@gmail.com.
        </>} handleClose={() => setReturnState(false)} />}
      <nav
        {...props}
        className={classes}
      >
        <ul className="list-reset">
          <li>
            <a style={{ cursor: "pointer" }} onClick={() => setPrivaryState(true)}>Privacy Policy</a>
          </li>
          <li>
            <a onClick={() => setReturnState(true)} style={{ cursor: "pointer" }}>Return Policy</a>
          </li>


        </ul>
      </nav>
    </>
  );
}

export default FooterNav;