import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Service Offered',
    paragraph: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
      id="serviceOffered"
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                </div> */}
                <h3 className="mt-0 mb-12">
                  Crital Care Solutions for Hospitals / Nursing Homes:
                </h3>
                <div className="m-0 text-sm">
                  <div className='mbm-12'>- Complete critical care solution for the ICU.</div>
                  <br />
                  <div className='mbm-12'>- Round the clock trained , qualified registrars for 24 hrs
                    cover.</div><br />
                  <div className='mbm-12'>- A qualified, experienced intensivist , as the team leader.</div><br />
                  <div className='mbm-12'>- Confident, protocol based management of all ICU cases
                    including necessary procedures.</div><br />
                  <div className='mbm-12'>- Regular ongoing training of ICU nurses .</div><br />
                  <div className='mbm-12'>- Super speciality references from our team of experts
                    whenever required.</div><br />
                  <div className='mbm-12'>- Complete responsibility of referral of cases to tertiary care
                    centres when need arises.</div><br />
                  <div className='mbm-12'>- Hassle free and reliable management of the Intensive Care
                    Unit , which forms the nerve centre of any hospital.</div>


                </div>
              </div>

              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.svg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>
            <p className="m-0 text-sm">
              A well equipped and well managed ICU, provides the much
              needed back up for the consultants in managing their cases,
              both in medical and surgical fields. An ICU managed by
              Plexus Criticare Group will boost the confidence of the
              consultants in the hospital and also enhance
              the patients’ reliability on the services provided in the
              hospital.
            </p>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                </div> */}
                <h3 className="mt-0 mb-12">
                  Client Hospitals:
                </h3>
                <p className="m-0 text-sm">
                  Currently Plexus Criticare Group is offering services at the
                  following hospitals in Bangalore.<br /><br />
                  - NU Hospital<br />&emsp;&emsp;<span className="text-xs">• Rajajinagar, Bangalore.</span><br />
                  - Ananya Hospital<br />&emsp;&emsp;<span className="text-xs">• Rajajinagar, Bangalore.</span><br />
                  - Chord road Hospital<br />&emsp;&emsp;<span className="text-xs">• Basaveswara Nagar, Bangalore.</span><br />
                  - St Theressa Hospital<br />&emsp;&emsp;<span className="text-xs">• Rajajinagar, Bangalore.</span><br />
                  - Punya Hospital<br />&emsp;&emsp;<span className="text-xs">• Basaveswara Nagar, Bangalore.</span><br />
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.svg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                </div> */}
                <h3 className="mt-0 mb-12">
                  Placement Services for Doctors:
                </h3>
                <p className="m-0 text-sm">
                  We are looking for enthusiastic, qualified doctors who like
                  undertaking challenging roles in ICUs. Freshers and
                  experienced doctors with the below mentioned qualifications
                  can contact us for opportunities of being part of dynamic ICU
                  teams . We allot suitable teams based on your preferred
                  location for work. Along with competent pay package, you
                  get the much needed ICU exposure and training from experts
                  in the field. We offer the flexibility of working full time or
                  part time and based on your experience you will be assured a
                  progress in the field of intensive care by way of promotion as
                  consultant Intensivist over a period of time.
                  So, come and grab the opportunity of working with the
                  experts, gaining intensive care experience and growing in
                  your career with the Plexus Criticare Group.

                </p>
              </div>

              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.svg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>
            <p className="m-0 text-sm">
              Click on register with us<br /><br /><Button tag="a" color="primary" wideMobile>
                    Register
                  </Button><br /><br />
              Qualifications required:<br />
              &emsp;&emsp;- MD/DNB ( medicine, anaesthesia, emergency medicine) -
              freshers or experienced.<br />
              &emsp;&emsp;- DA - freshers / experienced.<br />
              &emsp;&emsp;- MBBS - With minimum 4 years experience in ICU.<br />
              Register now for a secured and enriching career in intensive
              care.
            </p>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                </div> */}
                <h3 className="mt-0 mb-12">
                  Educational Services:
                </h3>
                <p className="m-0 text-sm">
                  We Offer Regular Training Courses for Doctors and Nurses In
                  Emergency Care.
                  The Courses Are Conducted by Trained and Certified
                  Professionals in the Field With Hands on Training in Life
                  Support.<br /><br />
                  Click on Educational Services <br />for the Announcement of Next
                  Set of Dates for the Courses.
                  <br /><br />
                  Courses Offered:
                  <br />
                  &emsp;&emsp;- Basic Life Support. <br />
                  &emsp;&emsp;- Advanced Cardiac Life Support.<br /><br />
                  Education and Training :<br />
                  &emsp;&emsp;- Basic Life Support Courses. <br />
                  &emsp;&emsp;- Advanced Cardiac Life Support Courses<br /><br />

                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-04.svg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>
            
            <p className="m-0 text-sm">
              Register With Us :<br />
              
              Doctors With the Following Qualifications Can Register With Us
              By Sending Your Resume to the Following <br />Email
              ID: Bangalore-ICU@Plexuscriticare.com<br />
              &emsp;&emsp;- MD/DNB ( Medicine, Anesthesia, Emergency Medicine) -
              Freshers or Experienced.<br />
              &emsp;&emsp;- DA - Freshers / Experienced<br />
              &emsp;&emsp;- MBBS - With Minimum 4 Years Experience in ICU
            </p>
            

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;