import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import OurTeam from '../components/sections/OurTeam';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import AboutUs from "../components/sections/AboutUs";
import ContactUs from "../components/sections/ContactUs";
const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <AboutUs invertMobile topDivider imageFill />
      <ContactUs topDivider />
      {/* <OurTeam invertMobile topDivider imageFill className="illustration-section-02"/> */}
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" /> */}
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
}

export default Home;