import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {


  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
      id="home"
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <div className="illustration-element-01" data-reveal-value="20px" data-reveal-delay="200">
              <Image
                src={require('./../../assets/images/logoTitle.svg')}
                alt="Title"
                width='100%' />
            </div>
            <div className="container-xs">
              <p className="mt-12 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Where every print tells a story of precision and perfection
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="#aboutUs">
                    Get started
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">

            <Image
              className="has-shadow"
              src={require('./../../assets/images/hero.svg')}
              alt="Hero"
              width={896}
              height={504} />
          </div>

        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;