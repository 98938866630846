import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="#home" >Home</a>
        </li>
        <li>
          <a href="#aboutUs" >About Us</a>
        </li>
       
        <li>
          <a href="#serviceOffered" >Service Offered</a>
        </li>
        <li>
          <a href="#ourClients" >Our Clients</a>
        </li>
        <li>
          <a href="#testimonials" >Testimonials</a>
        </li>
        <li>
          <a href="#contactUs" >Contact Us</a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;